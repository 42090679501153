<template>
  <div>
    <h3>
      {{ isWeb ? 'Web' : 'On-Site' }}
      {{ isVideo ? 'Video' : 'X-Ray' }}
      Viewings for Lot #{{ saleEntry.lotNumber }}
    </h3>
    <statistics-summaries-viewings-graph :viewings="viewings" :is-video="isVideo" />
    <ast-card
      v-for="(viewing, i) in viewings"
      :key="i"
    >
      <div class="row">
        <div class="col-sm-12">
          <ul>
            <li><label>Viewed by:</label>{{ viewing.userFullName }}</li>
            <li><label>Organization:</label>{{ viewing.organizationName }}</li>
          </ul>
        </div>
        <div class="col-sm-12">
          <ul>
            <li><label>Date Viewed:</label>{{ viewing.viewingDateTime | localizeDate }}</li>
            <li><label>X-Ray:</label>{{ viewing.xRayDuration }} mins</li>
            <li><label>Scope:</label>{{ viewing.videoDuration === '0:00' ? 'N/A' : viewing.videoDuration + 'mins' }}</li>
            <li v-if="requireEndoscopicDisclosure"><label>Endoscopic Disclosure:</label>{{ endoscopicDisclosure(viewing.endoscopyDisclosure) }}</li>
            <li><label>Client:</label>{{ viewing.clientName }}</li>
          </ul>
        </div>
      </div>
    </ast-card>
  </div>
</template>

<script>
import AstCard from '@components/Card.vue'
import StatisticsSummariesViewingsGraph from '@components/view/StatisticsSummariesViewingsGraph.vue'
export default {
  components: {
    AstCard,
    StatisticsSummariesViewingsGraph
  },
  props: {
    clinicCode: {
      type: String,
      required: true
    },
    saleEntry: {
      type: Object,
      required: true
    },
    isWeb: {
      type: Boolean,
      default: false
    },
    isVideo: {
      type: Boolean,
      default: false
    },
    requireEndoscopicDisclosure: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: true,
      viewings: []
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler () {
        this.loadViewings()
      }
    }
  },
  methods: {
    async loadViewings () {
      this.isLoading = true
      try {
        let params = {
          clinicCode: this.clinicCode,
          saleId: this.$route.query.saleId,
          saleEntryId: this.saleEntry.saleEntryId
        }
        let { userType, id } = this.$route.params
        if (userType === 'repository') params.securityToken = id
        if (userType === 'consigner') params.consignerId = id
        if (userType === 'client') params.consignerClientId = id
        const { data } = await this.$api.statistics.getViewings(params)
        this.viewings = data.filter(viewing => viewing.wasViewedOnline === this.isWeb && (! this.isVideo || viewing.videoDuration !== '0:00'))
      } finally {
        this.isLoading = false
      }
    },
    endoscopicDisclosure(ed) {
      return (ed && ed.toUpperCase() !== "NULL" ? ed : 'N/A')
    },
  }
}
</script>
