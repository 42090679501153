<template>
	<ast-statistics-layout :is-loading="isLoading">
		<ast-statistics-sidebar v-if="selectedSaleEntry" @close="closeSaleEntry">
			<ast-statistics-summaries-viewings
				:clinic-code="clinicCode"
				:sale-entry="selectedSaleEntry"
				:is-web="isWeb"
				:is-video="isVideo"
        :require-endoscopic-disclosure="requireEndoscopicDisclosure"
			/>
		</ast-statistics-sidebar>

		<ast-statistics-summaries-list
			v-if="!isLoading"
			status="Active"
			:sale-entries="saleEntries.active"
			:user-type="userType"
			@select="selectSaleEntry"
			@open-vet-report="openVetReport"
		/>

		<ast-statistics-summaries-list
			v-if="!isLoading"
			status="Sold"
			:sale-entries="saleEntries.sold"
			:user-type="userType"
			@select="selectSaleEntry"
			@open-vet-report="openVetReport"
		/>
	</ast-statistics-layout>
</template>

<script>
import AstStatisticsLayout from '@router/layouts/Statistics'
import AstStatisticsSidebar from '@components/layout/StatisticsSidebar'
import AstStatisticsSummariesList from '@components/view/StatisticsSummariesList'
import AstStatisticsSummariesViewings from '@components/view/StatisticsSummariesViewings'
import clone from '@utils/deepClone'
import { findAttachmentUrl } from '@utils/urlUtility'
import { openPdfDlg } from '@dialogs/attachmentDlg.vue'
import api from "@services/api";

export default {
	components: {
		AstStatisticsLayout,
		AstStatisticsSidebar,
		AstStatisticsSummariesList,
		AstStatisticsSummariesViewings,
	},
	props: {
		clinicCode: {
			type: String,
			required: true,
		},
		userType: {
			type: String,
			required: true,
		},
		id: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			saleEntries: {
				active: [],
				sold: [],
			},
			isWeb: false,
      isVideo: false,
			selectedSaleEntry: undefined,
			isLoading: true,
      requireEndoscopicDisclosure: false,
		}
	},
	watch: {
		'$route.query'(query) {
			if (query.saleEntryId) {
				const allEntries = [].concat(this.saleEntries.active, this.saleEntries.sold)
				this.selectedSaleEntry = allEntries.find(saleEntry => {
					return saleEntry.saleEntryId === query.saleEntryId
				})
				this.isWeb = query.isWeb === 'true'
				this.isVideo = query.isVideo === 'true'
			} else {
				this.selectedSaleEntry = undefined
			}
		},
		'$route.query.saleId'() {
			this.loadSaleEntries()
		},
	},
  async created() {
    const clinicInfo = await api.repository.get(this.clinicCode)
    this.requireEndoscopicDisclosure = clinicInfo.data.requireEndoscopicDisclosure
  },
	mounted() {
		this.closeSaleEntry()
		this.loadSaleEntries()
	},
	methods: {
		async loadSaleEntries() {
			this.isLoading = true
			try {
				let params = {
					clinicCode: this.clinicCode,
					saleId: this.$route.query.saleId,
				}
				if (this.userType === 'repository') params.securityToken = this.id
				if (this.userType === 'consigner') params.consignerId = this.id
				if (this.userType === 'client') params.consignerClientId = this.id
				const { data } = await this.$api.statistics.getSummaries(params)
				this.saleEntries = data
			} finally {
				this.isLoading = false
			}
		},
		selectSaleEntry({ saleEntry, isWeb, isVideo }) {
			const saleEntryId = saleEntry.saleEntryId
			if (this.selectedSaleEntry === saleEntry && this.$route.query.isWeb === isWeb) {
				return this.closeSaleEntry()
			}
			this.$router.push({
				query: { saleId: this.$route.query.saleId, saleEntryId, isWeb, isVideo },
			})
		},
		closeSaleEntry() {
			let query = clone(this.$route.query || {})
			delete query.saleEntryId
			delete query.isWeb
			this.$router.push({ query })
		},
		openVetReport(imageId) {
			const url = findAttachmentUrl({ imageId, clinicCode: this.clinicCode })
			openPdfDlg(url, false, false)
		},
	},
}
</script>
