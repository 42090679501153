<template>
	<div class="list">
		<h4>{{ status }} {{ translate('consignerStudy') }}s</h4>
		<ast-data-table
			v-if="mq.medium && saleEntries.length"
			:is-condensed="true"
			:is-hoverable="false"
			:is-full-width="false"
		>
			<thead>
				<tr>
					<th>{{ translate('consignerStudy') }}</th>
					<th v-if="userType === 'repository'">{{ translate('consigner') }}</th>
					<th class="text-right">On-Site</th>
					<th class="text-right">X-Ray</th>
					<th class="text-right">Scope</th>
					<th class="text-right">Web</th>
					<th class="text-right">X-Ray</th>
					<th class="text-right">Scope</th>
					<th class="text-right">Status</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="saleEntry in saleEntries" :key="saleEntry.saleEntryId">
					<td>{{ saleEntry.lotNumber }}</td>
					<td v-if="userType === 'repository'">{{ saleEntry.consignerName }}</td>
					<td class="text-right">
						<ul>
							<li>Views:</li>
							<li>Avg:</li>
							<li>Median:</li>
						</ul>
					</td>
					<td class="text-right">
						<ul>
							<li>
								<a
									v-if="saleEntry.onSiteViewsXRay"
									@click.prevent="$emit('select', { saleEntry, isWeb: 'false', isVideo: 'false' })"
								>
									{{ saleEntry.onSiteViewsXRay }}
								</a>
								<span v-else>0</span>
							</li>
							<li>{{ saleEntry.onSiteAverageDurationXRay }}</li>
							<li>{{ saleEntry.onSiteMedianDurationXRay }}</li>
						</ul>
					</td>
					<td class="text-right">
						<ul>
							<li>
								<a
									v-if="saleEntry.onSiteViewsVideo"
									@click.prevent="$emit('select', { saleEntry, isWeb: 'false', isVideo: 'true' })"
								>
									{{ saleEntry.onSiteViewsVideo }}
								</a>
								<span v-else>0</span>
							</li>
							<li>{{ saleEntry.onSiteAverageDurationVideo }}</li>
							<li>{{ saleEntry.onSiteMedianDurationVideo }}</li>
						</ul>
					</td>
					<td></td>
					<td class="text-right">
						<ul>
							<li>
								<a
									v-if="saleEntry.webViewsXRay"
									@click.prevent="$emit('select', { saleEntry, isWeb: 'true', isVideo: 'false' })"
								>
									{{ saleEntry.webViewsXRay }}
								</a>
								<span v-else>0</span>
							</li>
							<li>{{ saleEntry.webAverageDurationXRay }}</li>
							<li>{{ saleEntry.webMedianDurationXRay }}</li>
						</ul>
					</td>
					<td class="text-right">
						<ul>
							<li>
								<a
									v-if="saleEntry.webViewsVideo"
									@click.prevent="$emit('select', { saleEntry, isWeb: 'true', isVideo: 'true' })"
								>
									{{ saleEntry.webViewsVideo }}
								</a>
								<span v-else>0</span>
							</li>
							<li>{{ saleEntry.webAverageDurationVideo }}</li>
							<li>{{ saleEntry.webMedianDurationVideo }}</li>
						</ul>
					</td>
					<td>{{ status }}</td>
					<td>
						<ul class="unlist">
							<li v-for="(vetReportId, i) in saleEntry.vetReports" :key="vetReportId">
								<a @click.prevent="$emit('open-vet-report', vetReportId)">
									<svg-icon icon="file-pdf-o" fixed />
									Vet Report {{ saleEntry.vetReports.length > 1 ? i + 1 : '' }}
								</a>
							</li>
						</ul>
					</td>
				</tr>
			</tbody>
		</ast-data-table>
		<div v-if="!mq.medium && saleEntries.length" class="cards">
			<ast-card v-for="saleEntry in saleEntries" :key="saleEntry.saleEntryId" class="card">
				<div class="row">
					<div class="col-sm-12 col-md-4">
						<h3>{{ translate('consignerStudy') }} #{{ saleEntry.lotNumber }}</h3>
						<ul>
							<li v-if="userType === 'repository'">
								<label>Consigner:</label>
								{{ saleEntry.consignerName }}
							</li>
							<li>
								<label>Status:</label>
								{{ status }}
							</li>
						</ul>
					</div>
					<div
						class="col-sm-6 col-md-4"
						:class="{ clickable: !!saleEntry.onSiteViewsXRay }"
						@click.prevent="
							saleEntry.onSiteViewsXRay &&
								$emit('select', { saleEntry, isWeb: 'false', isVideo: 'false' })
						"
					>
						<ul>
							<li>
								<label><strong>On-Site X-Ray:</strong></label>
								<a v-if="saleEntry.onSiteViewsXRay">{{ saleEntry.onSiteViewsXRay }}</a>
								<span v-else>0</span>
							</li>
							<li>
								<label>Avg.:</label>
								{{ saleEntry.onSiteAverageDurationXRay }} mins
							</li>
							<li>
								<label>Median:</label>
								{{ saleEntry.onSiteMedianDurationXRay }} mins
							</li>
						</ul>
					</div>
					<div
						class="col-sm-6 col-md-4"
						:class="{ clickable: !!saleEntry.onSiteViewsVideo }"
						@click.prevent="
							saleEntry.onSiteViewsVideo &&
								$emit('select', { saleEntry, isWeb: 'false', isVideo: 'true' })
						"
					>
						<ul>
							<li>
								<label><strong>On-Site Scope:</strong></label>
								<a v-if="saleEntry.onSiteViewsVideo">{{ saleEntry.onSiteViewsVideo }}</a>
								<span v-else>0</span>
							</li>
							<li>
								<label>Avg.:</label>
								{{ saleEntry.onSiteAverageDurationVideo }} mins
							</li>
							<li>
								<label>Median:</label>
								{{ saleEntry.onSiteMedianDurationVideo }} mins
							</li>
						</ul>
					</div>
					<div
						class="col-sm-6 col-md-4"
						:class="{ clickable: !!saleEntry.webViewsXRay }"
						@click.prevent="
							saleEntry.webViewsXRay &&
								$emit('select', { saleEntry, isWeb: 'true', isVideo: 'false' })
						"
					>
						<ul>
							<li>
								<label><strong>Web X-Ray</strong></label>
								<a v-if="saleEntry.webViewsXRay">{{ saleEntry.webViewsXRay }}</a>
								<span v-else>0</span>
							</li>
							<li>
								<label>Avg.:</label>
								{{ saleEntry.webAverageDurationXRay }} mins
							</li>
							<li>
								<label>Median:</label>
								{{ saleEntry.webMedianDurationXRay }} mins
							</li>
						</ul>
					</div>
					<div
						class="col-sm-6 col-md-4"
						:class="{ clickable: !!saleEntry.webViewsVideo }"
						@click.prevent="
							saleEntry.webViewsVideo &&
								$emit('select', { saleEntry, isWeb: 'true', isVideo: 'true' })
						"
					>
						<ul>
							<li>
								<label><strong>Web Scope</strong></label>
								<a v-if="saleEntry.webViewsVideo">{{ saleEntry.webViewsVideo }}</a>
								<span v-else>0</span>
							</li>
							<li>
								<label>Avg.:</label>
								{{ saleEntry.webAverageDurationVideo }} mins
							</li>
							<li>
								<label>Median:</label>
								{{ saleEntry.webMedianDurationVideo }} mins
							</li>
						</ul>
					</div>
					<ul class="col-sm-6 col-md-4 unlist">
						<li v-for="(vetReportId, i) in saleEntry.vetReports" :key="vetReportId">
							<a @click.prevent="$emit('open-vet-report', vetReportId)">
								<svg-icon icon="file-pdf-o" fixed />
								Vet Report {{ saleEntry.vetReports.length > 1 ? i + 1 : '' }}
							</a>
						</li>
					</ul>
				</div>
			</ast-card>
		</div>
		<p v-if="!saleEntries.length" class="placeholder">
			No {{ status.toLowerCase() }} entries found.
		</p>
	</div>
</template>

<script>
import AstCard from '@components/Card'
import AstDataTable from '@components/DataTable'
export default {
	name: 'StatisticsSummariesList',
	components: {
		AstCard,
		AstDataTable,
	},
	props: {
		saleEntries: {
			type: Array,
			required: true,
		},
		userType: {
			type: String,
			required: true,
		},
		status: {
			type: String,
			required: true,
		},
	},
}
</script>

<style lang="scss" scoped>
h4 {
	position: sticky;
	padding: 0.75em;
	margin: 0 -15px;
	border-bottom: 1px solid var(--tertiary-border);
	top: 0;
	background: white;
	z-index: 1;
}
.placeholder {
	font-size: 1em;
	padding-bottom: 1em;
}
.cards {
	padding-top: 0.5em;
}
.data-table thead > tr > th {
	top: 44px; // offset for sticky h4
}
td ul {
	list-style-type: none;
}
.clickable:active {
	background: var(--tertiary-border);
}
.cards {
	.row {
		margin-left: -8px;
		margin-right: -8px;
	}
	[class^='col-'] {
		padding-left: 8px;
		padding-right: 8px;
	}
}
</style>
