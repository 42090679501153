<template>
	<modal-wrapper @close="isOpen = false" @show="isOpen = true">
		<transition name="slide-in-left" @leave="$emit('close')">
			<div v-if="isOpen" class="statistics-sidebar">
				<aside>
					<slot></slot>
				</aside>
				<transition name="fade">
					<div v-show="isLoading" class="loading">
						<svg-icon icon="spinner" pulse />
					</div>
				</transition>
				<button class="btn-close" @click.prevent="isOpen = false">
					<svg-icon icon="close" />
				</button>
			</div>
		</transition>
	</modal-wrapper>
</template>

<script>
import ModalWrapper from '@components/ModalWrapper'
export default {
	name: 'StatisticsSidebar',
	components: {
		ModalWrapper,
	},
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isOpen: false,
		}
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

.statistics-sidebar {
	width: $mqSmall;
	position: fixed;
	background: #fff;
	padding: 0.5em;
	right: 0;
	top: 0;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.07), 0 5px 15px 0 rgba(0, 0, 0, 0.14);
	overflow: auto;
	height: 100%;
	h3 {
		margin-bottom: 1em;
	}
	.btn-close {
		position: absolute;
		margin: 0;
		top: 5px;
		right: 5px;
		padding: 0;
		background: transparent;
		border: 0;
		font-size: 1.4rem;
		cursor: pointer;
		opacity: 0.5;
		&:hover {
			opacity: 1;
		}
	}
}
@media (max-width: $mqSmall) {
	.statistics-sidebar {
		width: 100%;
	}
}
</style>
