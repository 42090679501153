<template>
  <div class="bar-graph">
    <div class="bar-row header-row">
      <div class="bar-label">
        Mins
      </div>
      <div>
        Number of viewings
      </div>
    </div>
    <div
      v-for="(bar, i) in bars"
      :key="i"
      class="bar-row"
    >
      <div class="bar-label">
        {{ bar.label }}
      </div>
      <div class="bar-empty">
        <div
          class="bar-fill"
          :class="'percentage-' + getBarPercent(bar.value) + ' fill-' + i"
        >
          <span>{{ bar.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    viewings: {
      type: Array,
      required: true
    },
    isVideo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bars () {
      const ranges = [
        [0, 2],
        [2, 4],
        [4, 6],
        [6, 8],
        [8, 10],
        [10]
      ]
      return ranges.map(this.mapRangesToBars)
    }
  },
  methods: {
    mapRangesToBars (range) {
      let label = range[0]
      label += range.length === 1 ? '+' : '-' + range[1]
      const value = this.viewings.filter(this.filterViewingsInRange(range)).length
      return {
        label,
        value
      }
    },
    filterViewingsInRange (range) {
      return viewing => {
        let duration = this.isVideo ? viewing.videoDuration : viewing.xRayDuration
        let durationDecimal = this.timeToDecimal(duration)
        let isInRange = durationDecimal >= range[0]
        if (range.length > 1) isInRange = isInRange && durationDecimal < range[1]
        return isInRange
      }
    },
    getBarPercent (value) {
      // scale smaller bars against longest bar
      const lowestFullBar = 10 // lowest value to use for a full bar
      let maxBarValue = Math.max(...this.bars.map(x => x.value))
      maxBarValue = Math.max(lowestFullBar, maxBarValue)
      return Math.floor(value / maxBarValue * 100)
    },
    timeToDecimal(t) {
      let arr = t.split(':');
      let dec = parseInt((arr[1]/6)*10, 10);
      return parseFloat(parseInt(arr[0], 10) + '.' + (dec<10?'0':'') + dec);
}
  }
}
</script>

<style lang="scss" scoped>
.bar-graph {
  width: 90%;
  margin: 1em auto;
  border: 1px solid #ccc;
}
.bar-row {
  display: flex;
  height: 30px;
  padding: 0 2px;
}
.bar-label {
  display: inline-flex;
  align-items: center;
  width: 4rem;
  max-width: 4rem;
  height: 100%;
  padding: 4px;
  border-right: 1px solid #ccc;
}
.bar-empty {
  display: inline-flex;
  flex: 2;
  align-items: center;
  height: 100%;
  padding: 2px 0;
}
.bar-fill {
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
  width: 0;
  min-width: 1.5em;
  height: 100%;
  padding-right: 0.5em;
  color: #fff;
  background: #8bc24c;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  transition: width 0.3s linear;
}
.percentage-0 {
  opacity: 0;
}
.fill-1 {
  background: #59d090;
}
.fill-2 {
  background: #34bac5;
}
.fill-3 {
  background: #819dcc;
}
.fill-4 {
  background: #bd78a2;
}
.fill-5 {
  background: #c66561;
}
.header-row {
  font-size: 0.9em;
  background: #f0f0f0;
  div {
    display: flex;
    align-items: center;
    padding: 4px;
  }
}

@for $i from 1 through 100 {
  .bar-fill.percentage-#{$i} {
    $value: ($i * 1%);

    width: $value;
  }
}
</style>
